.tabsPanel {
  max-width: 600px;
  position: sticky;
  top: 10px;
}

.closeTabsPanelBtn {
  position: absolute;
  z-index: 10;
  top: -1px;
  right: 0;
  color: var(--white-color);
}

.tabsContainer {
  max-width: 536px;
  padding: 32px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 1px 5px rgb(0 0 0 / 12%);
}

.scroller {
  height: calc(100vh - 140px);
  min-height: 600px;
  overflow-y: scroll;
  transition: height 0.3s;
}

.scrollerShort {
  height: calc(100vh - 330px);
  min-height: 400px;
}
