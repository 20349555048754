.containerAvatarInfo {
  display: flex;
  align-items: flex-start;
  margin-bottom: 26px;

  .info {
    margin-left: 8px;

    .contactName {
      margin-bottom: 10px;
    }

    .dateInfo {
      color: var(--grey-lighten-6);
      position: relative;
      margin-top: 10px;

      .deleteBtn {
        position: absolute;
        top: -3px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
