.component__wrapper {
  display: block;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.alerts__block {
  margin-bottom: 34px;
}

.alerts__item {
  margin-bottom: 8px;
}
