.tableHeader {
  display: flex;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.icon {
  width: 18px;
  color: var(--text-color-black-54);
}

.active {
  color: var(--custom-primary);
}
