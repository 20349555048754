.box {
  display: flex;
  align-items: center;

  &.reversed {
    flex-direction: row-reverse;
  }
}

.name {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 24px;

  &.reversed {
    margin-right: 24px;
    margin-left: 0;
  }
}
