.inputError {
  :global(.Mui-error) {
    color: var(--text-color-black-54);
  }

  :global(.MuiFilledInput-underline.Mui-error) {
    color: var(--text-color-black-54);
    border: 1px solid var(--error-color);
    border-radius: 4px;
  }

  :global(.MuiFilledInput-underline.Mui-error::before) {
    border: none;
  }

  :global(.MuiFilledInput-underline.Mui-error::after) {
    border: none;
  }

  :global(.MuiFilledInput-underline.Mui-error input) {
    border-radius: 4px;
  }
}
