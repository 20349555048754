.tableHead {
    background-color: var(--grey-lighten-3);
  }
  
  .tableHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .tableHead th:last-child div {
    display: none;
  }
  
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }
  
  .icon {
    width: 18px;
    color: var(--text-color-black-54);
  }
  
  .active {
    color: var(--custom-primary);
  }
  
  .tableBody {
    tr:hover {
      cursor: pointer;
      background: var(--transparent-green);
      transform: scale(1, 1);
      box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    }
  }
  