.alertWrapper {
  background-color: var(--alert-bg-color);
  color: var(--alert-text-color);
  padding-bottom: 0.5rem;
}

.alertHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  cursor: pointer;
}

.badge .MuiBadge-badge {
  top: 1rem;
}

.collapseContent {
  padding: 0.5rem 2rem 1rem;
}

.alertItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  color: var(--alert-text-color);

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.buttonGroup {
  display: flex;
}

.includeButton {
  margin-inline-end: 1rem;
}

.collapseButton {
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
}