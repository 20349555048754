@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap";
@import "valtech-core/styles/colors.scss";
@import "valtech-core/styles/tables.scss";
@import "valtech-core/styles/form.scss";

body {
  font-size: 16px;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  min-height: 100vh;
  color: var(--text-color-main);
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.formError {
  color: var(--error-color);
}

.text-capitalized {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

.tableContainer-xKiller {
  position: relative;
  border: 1px solid var(--table-border);

  td {
    padding: 10px 16px;
    height: 32px;

    div[class^="MuiChip"] {
      height: 28px;
      line-height: 1;
    }

    button[class^="MuiButtonBase-root"] {
      padding: 4px;
    }
  }
}
