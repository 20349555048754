.TableRow--hover:hover, .TableRow--active {
  cursor: pointer;
  background-color: var(--transparent-green) !important;
  transform: scale(1, 1);
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
}

.TableRow--disabled :where(th, svg) {
  color: var(--grey-lighten-6) !important;
}
