.pageWrapper {
  display: flex;
  justify-content: center;
}

.logo {
  margin-bottom: 90px;
}

.btn {
  padding: 15px 80px;
}
