@import "valtech-core/styles/mixins/toRem.scss";

.btn {
  font-size: to-rem(14px);
  font-family: Roboto, sans-serif;
  font-weight: 500;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding-left: 16px;
  padding-right: 16px;
}
