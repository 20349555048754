%flex-center { // eslint-disable-line
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background: var(--grey-lighten-5);
}

.information {
  @extend %flex-center;

  flex-grow: 1;
}

.controlsRow {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.informationIcon {
  background: var(--white-color);
  margin-right: 20px;
}

.informationTitle {
  margin-right: 20px;
}

.actionTitle {
  color: var(--grey-lighten-6);
  margin-right: 20px;
}

.submitBudget__title {
  margin-bottom: 30px;
  text-transform: capitalize;
}
