.tabs {
  &[data-theme="blue"] {
    --background-color: var(--custom-primary);
    --indicator-color: var(--tab-indicator-red-color);
    --text-color: var(--inactive-tab-text-color);
    --selected-text-color: var(--white-color);
  }

  &[data-theme="white"] {
    --background-color: var(--white-color);
    --indicator-color: var(--custom-primary);
    --text-color: var(--text-color-black-54);
    --selected-text-color: var(--custom-primary);
  }

  background: var(--background-color);

  :global(.MuiTabs-indicator) {
    background: var(--indicator-color);
  }

  :global(.MuiTab-root) {
    color: var(--text-color);
  }

  :global(.Mui-selected) {
    color: var(--selected-text-color);
  }
}
