.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  min-width: 0;
  padding: 8px !important;
  border-radius: 20px !important;

  & svg {
    fill: var(--inactive-filter-field);
  }

  &:hover {
    & svg {
      fill: var(--custom-primary);
    }
  }
}
