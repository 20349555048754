.fieldsWrapper {
  composes: fieldsWrapper from "../shared.module.scss";
  align-items: center;
}

.input {
  composes: input from "../shared.module.scss";
}

.button {
  display: block;
  margin-left: auto;
}

.buttonRound {
  position: absolute;
  top: -3px;
  right: -23px;
  min-width: 0;
  margin: 0;
  padding: 8px !important;
  border-radius: 20px !important;

  & svg {
    fill: var(--inactive-filter-field);
  }

  &:hover {
    & svg {
      fill: var(--custom-primary);
    }
  }
}
