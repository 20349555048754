.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1.5px solid rgb(0 0 0 / 12%);
  padding: 10px;
  background: var(--grey-lighten-5);
  margin-bottom: 30px;
}

.information {
  display: flex;
  align-items: center;
}

.action {
  display: flex;
  align-items: center;
  margin: 10px;
}

.informationIcon {
  background: var(--white-color);
  margin-right: 20px;
}

.informationTitle {
  margin-right: 20px;
}

.actionTitle {
  color: var(--grey-lighten-6);
  margin-right: 20px;
}
