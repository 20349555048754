.table {
  * p {
    margin: 0;
  }

  * td {
    padding-top: 14px;
    padding-bottom: 14px;
    color: inherit;
  }
}

.tableHeader {
  background-color: var(--grey-lighten-3);
}

.tableHeader__title {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--text-color-main);
}

.tableHeader__subtitle {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--text-color-black-54);
}

.markedRed {
  color: var(--table-mark-red) !important;
}

.markedOrange {
  color: var(--custom-warning) !important;
}

.markedAdditionalExpenses {
  color: var(--table-mark-additional-expenses);
  font-size: 0.8rem;

  .inactive & {
    color: var(--table-mark-additional-expenses-disabled);
  }
}

.inactive {
  color: var(--grey-lighten-6);
}

.tableHeaderItem {
  display: flex;
  align-items: center;

  &.clickable {
    cursor: pointer;
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.icon {
  width: 18px;
  color: var(--text-color-black-54);
}

.tableRowHighlighted {
  &:hover {
    cursor: pointer;
    background: var(--transparent-green);
    transform: scale(1, 1);
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  }

  td {
    color: inherit;
  }
}

.activeRow {
  cursor: pointer;
  background: var(--corn-silk);

  // background: #72b2f2;
  transform: scale(1, 1);
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);

  td {
    color: inherit;
  }
}

.tableFooter__tableCell {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--text-color-main);
}

.tableBody__dateCell {
  min-width: 80px;

  @media screen and (min-width: 1700px) {
    min-width: 160px;
  }

  span:last-of-type {
    white-space: nowrap;
  }
}

.tableBody__hoursCell {
  min-width: 80px;
}
