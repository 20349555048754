$breakpoints: (
  "sm": (min-width: 576px),
  "md": (min-width: 768px),
  "lg": (min-width: 1024px),
  "xl": (min-width: 1280px),
  "xxl": (min-width: 1440px),
  "xxxl": (min-width: 1600px),
) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "No such breakpoint as `#{breakpoint}`.";
  }
}
