@import "valtech-core/styles/breakpoints.scss";
@import "valtech-core/styles/mixins/toRem.scss";

$cell-width: 115px;
$small-font-size: 12px;
$cell-width-accordion: 430px;

%total-colored {
  background: var(--orange-lighten-4);
}

$bottom-row-second: 54;

@mixin borderShadow {
  box-shadow: 2px -5px 4px rgb(128 128 128 / 9%);
}

.container {
  box-shadow: none;
  background: none;
  border-radius: 0;
}

.table {
  table-layout: fixed;

  td,
  th {
    width: $cell-width;
    font-size: $small-font-size;

    @include respond-to("xl") {
      width: auto;
    }
  }

  .dataRowTitleCell {
    padding-right: 0;
    padding-left: 4px;
  }
}

.scrollableTable {
  min-width: 100%;

  @media screen and (min-width: 1366px) and (max-width: 1600px) {
    zoom: 0.87;
  }

  @include respond-to("xl") {
    min-width: 110vw;
  }

  @include respond-to("xxxl") {
    min-width: auto;
  }
}

.innerTable {
  tbody tr:hover {
    background: var(--transparent-green);
    transform: scale(1, 1);
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);

    th.colored:not(.generalCellTitle) {
      background: var(--transparent-green);
    }

    td.footerColored {
      background: var(--transparent-green);
    }
  }

  td,
  th {
    padding: 4px 16px;
    height: 48px;
  }

  td {
    &:last-child {
      border-left: 1px solid rgb(224 224 224 / 100%);
    }
  }

  .cellSpacing {
    padding: 16px;
  }
}

.innerTableFooter {
  border: 2px solid rgb(188 188 188);
}

.tableHeading {
  th {
    background: var(--custom-primary);
    color: var(--white-color);
    padding: 14px 16px 8px;

    & + th {
      border-right: 1px solid var(--white-color);
    }

    &:first-child,
    &:last-child {
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 6px;
        position: absolute;
        top: 0;
        left: 0;
        background: var(--white-color);
      }
    }
  }

  .totalCell {
    background: var(--custom-warning);
  }
}

th,
td {
  &.stickyCell {
    position: sticky;
    left: 0;
    z-index: 1;
    width: 430px;

    &.stickToRight {
      left: auto;
      right: 0;
    }
  }

  &.overlapped {
    z-index: 3;
  }

  &.stickyBottom {
    bottom: 0;
    left: auto;
  }

  &.stickyLeft {
    left: 0;
  }

  &.totalCell {
    width: 145px;
  }

  &.totalColored {
    @extend %total-colored;

    height: auto;
  }
}

.colored {
  background: var(--grey-lighten-5);
}

.generalCellTitle {
  background: var(--grey-lighten-3);
}

.totalColoredCell {
  background: var(--corn-silk);
  font-weight: bold;
}

.shadow {
  box-shadow: 2px -5px 4px rgb(128 128 128 / 9%);
}

.textWarning {
  color: var(--custom-warning);
}

.textError {
  color: var(--custom-error);
}

.textSuccess {
  color: var(--done-color);
}

.textSecondary {
  color: var(--black-color);
  opacity: 0.6;
}

.input {
  :global(.MuiInput-root) {
    color: currentcolor;
    font-size: to-rem(14px);

    &::before {
      display: none;
    }

    input {
      padding: 0;
      text-align: right;
    }
  }

  :global(.MuiInputBase-readOnly) {
    pointer-events: none;

    &::after {
      display: none;
    }
  }

  & + :global(.xk-form-error) {
    position: absolute;
  }
}

.monthStatus {
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 2px;
    left: 0;
    background: var(--white-color);
  }

  &.statusNew {
    &::before {
      background: var(--custom-error);
    }
  }

  &.statusDraft {
    &::before {
      background: var(--custom-warning);
    }
  }

  &.statusDone {
    &::before {
      background: var(--done-color);
    }
  }
}

.accordion {
  box-shadow: none;
  border-radius: 0;

  :global(.MuiAccordionSummary-root) {
    flex-direction: row-reverse;

    :global(.MuiTypography-root) {
      position: sticky;
      left: 40px;
      z-index: 1;
    }

    :global(.MuiAccordionSummary-expandIconWrapper) {
      position: sticky;
      left: 16px;
      z-index: 1;
    }
  }

  :global(.MuiAccordionDetails-root) {
    padding: 0;
  }
}

.accordionTableFooter {
  :global(.MuiAccordionSummary-root) {
    background-color: var(--corn-silk);
  }
}

.footer {
  color: var(--text-color-main);

  td,
  th {
    background: var(--white-color);
    color: currentcolor;
  }

  td {
    &.textStatusDone {
      color: var(--done-color);
    }

    &.textStatusDraft {
      color: var(--custom-warning);
    }

    &.stickyCell {
      width: $cell-width;
    }

    &:last-child {
      @extend %total-colored;
    }
  }
}

.invoicedCell {
  padding-top: 4px;
  padding-bottom: 4px;
}

.buttonCell {
  padding: 4px;

  button {
    padding: 0;
    font-size: 12px;
  }
}

.stickyRowSecond {
  td,
  th {
    // hardcoded value -> equals to footer tr height
    bottom: #{$bottom-row-second}px;

    @include borderShadow
  }
}

:global(.comment-tooltip) {
  z-index: 1;
}

.emptyInput {
  border-bottom: 2px solid var(--error-color);
}

.footerAccordionTableRecords {
  color: var(--text-color-main);

  td,
  th {
    background: var(--white-color);
    color: currentcolor;
    box-shadow: none
  }

  td {
    &:last-child {
      @extend %total-colored;
    }
  }
}
