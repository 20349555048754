.dragNDropWrapper {
  border: 1px dashed grey;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
  align-items: center;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 24px;
  p {
    &:first-child {
      font-weight: bold;
    }
    padding: 0;
    margin: 0;
  }
}

.dragNDrop {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.titleWrapper {
  position: relative;
  color: #c4c4c4;
  display: flex;
  &.active {
    &:before {
      background: #fafafa;
      position: absolute;
      content: "Title";
      padding: 0 12px;
      left: 12px;
      top: -11px;
    }
  }
}

.inputTitle {
  border: 1px solid #c4c4c4;
  margin-bottom: 12px;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px;
  width: 100%;
}

.fileTypeWarning {
  text-align: center;
  font-size: 1rem;
}

.inputFile {
  display: none;
}

.uploadFileText {
  color: #1976d2;
}

.submitButton {
  background-color: #1976d2;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
  border: none;
  &:disabled {
    background-color: #c4c4c4;
  }
}

.fileLastUpdatedAtWrapper {
  background-color: var(--grey-lighten-5);
  padding: 1.33rem 0;
  margin: 0;
  text-align: center;
  font-size: 1rem;

  & > span {
    font-size: 1rem;
  }

  &__title {
    color: rgba(0 0 0 / 54%)
  }
}
