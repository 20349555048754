.inputField {
  label {
    left: -10px;
  }

  input {
    background-color: var(--white-color);
    padding-left: 2px;
  }
}

.selectField {
  margin-top: 4px;
}
