.modalBody {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 90%;
  max-height: calc(100vh - 20px);
  padding: 24px;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  border: 2px solid var(--grey-lighten-5);
  border-radius: 4px;
  box-shadow: 0 5px 5px rgba(0 0 0 / 20%), 0 9px 10px rgba(0 0 0 / 14%), 0 5px 14px rgba(0 0 0 / 12%);
}

.modalBodyInner {
  width: 100%;
  height: 100%;
}

.modalBody--close-btn {
  //padding-top: 50px;
}

.modalBody--mid,
.modalBody--big {
  width: 100%;
}

.modalBody--mid {
  max-width: 720px;
}

.modalBody--big {
  max-width: 1480px;
}

.modalBody--scroll {
  overflow-y: auto;

  .modalBodyInner {
    width: 100%;
    height: 100%;
  }
}
