.link {
  color: currentcolor;
  text-decoration: none;
  width: 100%;
}

:global(.active) {
  color: var(--custom-primary);

  :global(.MuiListItemIcon-root) {
    color: var(--custom-primary);
  }
}

.logo {
  width: 100px;
}
