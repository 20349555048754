.wrapper {
  display: flex;
  padding: 14px 140px 14px 40px;
  align-items: center;
  justify-content: space-between;
  background: var(--white-color);
  box-shadow: 0 -5px 5px rgb(0 0 0 / 3%), 0 -9px 10px rgb(0 0 0 / 2%), 0 -5px 14px rgb(0 0 0 / 6%);
}

.text {
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}
