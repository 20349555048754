.text {
  font-weight: 400;
  font-style: normal;
  color: inherit;
  text-transform: none;
  text-decoration: none;

  &-medium {
    font-weight: 500;
  }

  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-lowercase {
    text-transform: lowercase;
  }

  &-italic {
    font-style: italic;
  }

  &-oblique {
    font-style: oblique;
  }

  &-overline {
    text-decoration: overline;
  }

  &-line-through {
    text-decoration: line-through;
  }

  &-underline {
    text-decoration: underline;
  }
}
