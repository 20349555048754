.BlurContainer {
  position: relative;
}

.BlurContainer__blur {
  display: none;
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: var(--inactive-tab-text-color);
}

.BlurContainer__blur--active {
  display: block;
}
