.selectField {
  height: 40px;
}

.overlay {
  position: relative;
}

.information__text {
  font-size: 14px;
  color: var(--grey-lighten-6);
}
