:root {
  --text-color-main: #000;
  --error-color: #d32f2f;
  --text-color-black-54: rgba(0 0 0 / 54%);
  --white-color: #fff;
  --black-color: #000;
  --grey-lighten-5: #fafafa;
  --grey-lighten-3: #eee;
  --grey-lighten-6: #9e9e9e;
  --custom-primary: #1976d2;
  --custom-error: #ff5252;
  --inactive-tab-text-color: rgba(255 255 255 / 54%);
  --table-border: #e5e5e5;
  --tab-indicator-red-color: #e10050;
  --draft-color: #ffd740;
  --custom-warning: #fb8c00;
  --done-color: #4caf50;
  --corn-silk: #fff8e1;
  --orange-lighten-4: #ffe0b2;
  --deep-orange-darken-1: #f4511e;
  --deep-orange-darken-4: #e65100;
  --transparent-green: rgb(217 233 217);
  --inactive-filter-field: #707070;
  --grey-88: #e0e0e0;
  --alert-bg-color: #fff4e5;
  --alert-text-color:  #663C00;
  --table-mark-red: #ff0000;
  --table-mark-additional-expenses: #0000ff;
  --table-mark-additional-expenses-disabled: #6464ff;
}
