.container {
  justify-content: space-between;
  margin-bottom: 1rem; 
  display: flex;
}

.timesheetList {
  list-style: none;
  padding-left: 0;
  margin-left: 0;

  ul {
    padding-left: 0.75rem; 
  }
}

.timesheetListItem {
  padding-left: 1.125rem;
}

.timesheetListItemIcon {
  position: relative;
  cursor: pointer;

  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.3125rem 0.3125rem 0 0.3125rem;
    border-color: var(--grey-lighten-6) transparent transparent transparent;
    transform: rotate(0deg);
    content: '';
    position: absolute;
    left: 0;
    top: 0.625rem;
    transition: transform 0.3s ease;
  }

  &.collapsed:before {
    transform: rotate(-90deg);
  }
}

.timesheetListHoursMain {
  font-weight: bold;
}

.timesheetListHours,
.timesheetListHoursMain {
  margin-left: 0.5rem;
}

.timesheetTitle {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 0.5rem;
}

.timesheetDetails {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 0.5rem; 
}

.consultantDetails {
  font-weight: bold;
}

.selected {
  position: relative;

  &:before {
    position: absolute;
    bottom: -0.625rem;
    right: -1rem; 
    left: -1rem; 
    top: -0.625rem;
    content: '';
  }
}