.AssociatedClientItem--active {
  background-color: var(--transparent-green) !important;
  transform: scale(1, 1);
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
}

.inputError {
  :global(.Mui-error) {
    color: var(--text-color-black-54);
    border-color: var(--error-color);
  }
}
