.fieldsWrapper {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.input {
  width: 50%;
}

.button {
  display: block;
}

.button__left_margin {
  display: block;
  margin-left: auto;
}
