.overlay {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--inactive-tab-text-color);
}
