.tablePagination {
  div[class^="MuiToolbar-root"] {
    padding: 0 30px;
    justify-content: center;
  }

  div[class^="MuiTablePagination-spacer"] {
    display: none;
  }
}
