.disabledArea {
  pointer-events: none;
  opacity: 0.5;
}

.rowSubmitted {
  opacity: 0.5;
  pointer-events: none;
}

.table {
  th,
  td {
    padding: 8px;
  }

  .MuiBox-root {
    background: red !important;

    &::before {
      content: none;
    }
  }

  .consultantNameCol {
    width: 160px;
  }

  .feeCol {
    width: 75px;
  }

  .iconsCol {
    padding-left: 0;
    padding-right: 3px;
    width: 24px;
  }

  .allocHoursCol {
    width: 75px;
  }
}

.datePicker {
  width: 136px;

  fieldset {
    //border: none;
    border-radius: 0;
    border-width: 0 0 1px 0;
  }

  input {
    padding: 4px 0 5px;
  }
}

.headerSelect {
  width: 300px;
}
