.form {
  margin-top: 32px;

  .button {
    padding: 8px;
    border-radius: 20px;
    min-width: unset;

    & svg {
      fill: var(--inactive-filter-field);
    }

    &:hover {
      & svg {
        fill: var(--custom-primary);
      }
    }
  }
}
